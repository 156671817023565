import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogConfig,
} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import dayjs from "dayjs";

import { BrandPartner } from "../../../shared/models/brandPartner";
import { BrandPartnerBudget } from "../../../shared/models/brandPartnerBudget";
import {
  CampaignCurrency,
  CampaignCurrencySymbol,
} from "../../../shared/enums/campaign.enums";
import { SlimBrandPartner } from "../../../shared/models/SlimBrandPartner";
import { BrandPartnerBudgetService } from "../../brand-campaign/shared/services/brand-partner-budget.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { CreateBrandPartnerBudgetItemData } from "../../../shared/services/parameters/create-brand-partner-budget-item-data";
import { CreateBrandPartnerBudgetsData } from "../../../shared/services/parameters/create-brand-partner-budgets-data";
import { CreateBrandPartnerBudgetFailedErrorKey } from "../../../shared/services/responses/create-brand-partner-budget-failed-response";
import { CreateBrandPartnerBudgetsResponse } from "../../../shared/services/responses/create-brand-partner-budgets-response";
import { BrandPartnerService } from "../../shared/services/brand-partner.service";
import { getDateFormatSignal } from "../../../shared/services/date.service";

export type BrandPartnersEditBudgetDialogData = SlimBrandPartner;
export type BrandPartnersEditBudgetDialogResult = true;

@Component({
  selector: "app-brand-partners-edit-budget-dialog",
  templateUrl: "./brand-partners-edit-budget-dialog.component.html",
  styleUrls: ["./brand-partners-edit-budget-dialog.component.scss"],
})
export class BrandPartnersEditBudgetDialogComponent implements OnInit {
  public brandPartner!: BrandPartner;
  public maxBudget = 10000;
  public budgetAmount = 0;
  public maxDate: Date;
  public minDate: Date;
  public selectedBrandPartnerBudget: BrandPartnerBudget;
  public isEditingExistingBudget = false;
  public campaignCurrency = CampaignCurrency;
  public currencySymbol: CampaignCurrencySymbol = CampaignCurrencySymbol.EUR;
  public creating = false;
  public updatingOrDeleting = false;
  public form!: UntypedFormGroup;
  public invalidDates = false;
  public datesInExistingRange = false;
  public showDeleteBudgetConfirmMessage = false;
  public existingBudgetEditedOrDeleted = false;

  public readonly getDateFormat = getDateFormatSignal();

  private budgetsChanged = false;

  constructor(
    private readonly dialogRef: MatDialogRef<
      BrandPartnersEditBudgetDialogComponent,
      BrandPartnersEditBudgetDialogResult
    >,
    private readonly brandPartnerService: BrandPartnerService,
    private readonly brandPartnerBudgetService: BrandPartnerBudgetService,
    private readonly notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA)
    public readonly slimBrandPartner: BrandPartnersEditBudgetDialogData,
    private readonly translateService: TranslateService,
  ) {
    this.maxDate = dayjs().add(2, "years").toDate();
    this.minDate = dayjs().subtract(2, "years").toDate();
    this.selectedBrandPartnerBudget = BrandPartnerBudget.create();
  }

  static getConfig(): MatDialogConfig<BrandPartnersEditBudgetDialogData> {
    // Not being able to close by clicking outside the dialog or pressing escape
    // might be surprising for some power-users but this way the only way to close
    // the modal is via `close()` method, which ensures correct return value.
    return {
      disableClose: true,
    };
  }

  public ngOnInit(): void {
    this.getBrandPartner();
    this.initForm();
  }

  public initForm(): void {
    this.form = new UntypedFormGroup({
      fromDate: new UntypedFormControl({
        value: this.selectedBrandPartnerBudget.fromDate,
        disabled: false,
      }),
      toDate: new UntypedFormControl({
        value: this.selectedBrandPartnerBudget.toDate,
        disabled: false,
      }),
      currency: new UntypedFormControl({
        value: this.selectedBrandPartnerBudget.currency,
        disabled:
          this.selectedBrandPartnerBudget.hasPartnerAssignedBudgetToCampaigns,
      }),
      budget: new UntypedFormControl({
        value: this.selectedBrandPartnerBudget.budget,
        disabled: false,
      }),
    });
  }

  public getBrandPartner(): void {
    this.brandPartnerService
      .getBrandPartner(this.slimBrandPartner.id)
      .subscribe({
        next: (brandPartner: BrandPartner) => {
          this.updatingOrDeleting = false;
          this.brandPartner = brandPartner;
        },
        error: () => {
          this.notificationService.error("shared.errorLoadingTheList");
        },
      });
  }

  public close(): void {
    this.dialogRef.close(this.budgetsChanged ? true : undefined);
  }

  public validateAndUpdateStartDate(value: dayjs.Dayjs): void {
    this.selectedBrandPartnerBudget.fromDate = value.toDate();
    if (
      this.selectedBrandPartnerBudget.toDate <
      this.selectedBrandPartnerBudget.fromDate
    ) {
      this.selectedBrandPartnerBudget.toDate = dayjs(
        this.selectedBrandPartnerBudget.fromDate,
      )
        .add(1, "month")
        .toDate();
      this.form.controls.toDate.setValue(
        this.selectedBrandPartnerBudget.toDate,
      );
    }
    this.existingBudgetEditedOrDeleted = false;
    this.validateExistingDateRange();
  }

  public validateAndUpdateEndDate(value: dayjs.Dayjs): void {
    this.selectedBrandPartnerBudget.toDate = value.toDate();
    this.existingBudgetEditedOrDeleted = false;
    this.validateExistingDateRange();
  }

  public validateExistingDateRange(): void {
    this.datesInExistingRange = false;

    if (
      this.selectedBrandPartnerBudget.fromDate &&
      this.selectedBrandPartnerBudget.toDate
    ) {
      this.brandPartner.budgets.forEach((budget) => {
        const areDatesInExistingRange =
          budget.fromDate <= this.selectedBrandPartnerBudget.toDate &&
          budget.toDate >= this.selectedBrandPartnerBudget.fromDate;

        const isEditingExistingBudget: boolean =
          this.selectedBrandPartnerBudget.id === budget.id;

        if (areDatesInExistingRange && !isEditingExistingBudget) {
          this.datesInExistingRange = true;
          return;
        }
      });
    }
  }

  public isSaveDisabled(): boolean {
    if (this.datesInExistingRange) {
      return true;
    }
    if (this.existingBudgetEditedOrDeleted) {
      return false;
    }
    if (this.isEditingExistingBudget) {
      return true;
    }
    if (this.creating) {
      return true;
    }
    return this.selectedBrandPartnerBudget.budget === 0;
  }

  public selectBudgetToEdit(budget: BrandPartnerBudget): void {
    this.selectedBrandPartnerBudget = budget.clone();
    this.initForm();
    this.isEditingExistingBudget = true;
  }

  public unselectBudgetToEdit(): void {
    this.selectedBrandPartnerBudget = BrandPartnerBudget.create();
    this.initForm();
    this.isEditingExistingBudget = false;
  }

  public updateBrandPartnerBudget(): void {
    this.updatingOrDeleting = true;
    this.brandPartnerBudgetService
      .updateBrandPartnerBudget(
        this.brandPartner.id,
        this.selectedBrandPartnerBudget,
      )
      .subscribe({
        next: () => {
          this.unselectBudgetToEdit();
          this.getBrandPartner();
          this.existingBudgetEditedOrDeleted = true;
          this.budgetsChanged = true;
        },
        error: (err) => {
          this.updatingOrDeleting = false;
          if (
            err.error?.key ===
            CreateBrandPartnerBudgetFailedErrorKey.OverlappingTimeFrame
          ) {
            this.resetFormErrors();
            this.markDatesAsInvalid();
            this.notificationService.error(
              "brand.partners.editBudget.overlappingTimeFrameError",
            );
          } else if (
            err.error?.key ===
            CreateBrandPartnerBudgetFailedErrorKey.BudgetLessThanAllocated
          ) {
            this.notificationService.error(
              this.translateService.instant(
                "brand.partners.editBudget.budgetLessThanAllocatedError",
                {
                  currency: this.selectedBrandPartnerBudget.currency,
                  budgetAllocated:
                    this.selectedBrandPartnerBudget.budgetAssignedToCampaigns,
                },
              ),
            );
          } else {
            this.notificationService.error("shared.errorSavingTheChanges");
          }
        },
      });
  }

  public resetFormErrors(): void {
    this.invalidDates = false;
    this.form.controls.fromDate.setErrors({});
    this.form.controls.toDate.setErrors({});
    this.form.controls.toDate.markAsUntouched();
  }

  public markDatesAsInvalid(): void {
    this.invalidDates = true;
    this.form.controls.fromDate.setErrors({ incorrect: true });
    this.form.controls.fromDate.markAsTouched();
    this.form.controls.toDate.setErrors({ incorrect: true });
    this.form.controls.toDate.markAsTouched();
  }

  public createBrandPartnerBudget(): void {
    if (this.existingBudgetEditedOrDeleted) {
      this.close();
      return;
    }

    this.validateExistingDateRange();
    if (this.datesInExistingRange) {
      return;
    }

    const budgets: CreateBrandPartnerBudgetItemData[] = [
      new CreateBrandPartnerBudgetItemData(
        this.brandPartner.id,
        this.brandPartner.partnerCompanyName,
        dayjs(this.selectedBrandPartnerBudget.fromDate).startOf("day").toDate(),
        dayjs(this.selectedBrandPartnerBudget.toDate).endOf("day").toDate(),
        this.selectedBrandPartnerBudget.budget,
        this.selectedBrandPartnerBudget.currency,
      ),
    ];

    this.creating = true;
    this.brandPartnerBudgetService
      .createBrandPartnerBudgets(new CreateBrandPartnerBudgetsData(budgets))
      .subscribe({
        next: (
          createBrandPartnerBudgetsResponse: CreateBrandPartnerBudgetsResponse,
        ) => {
          this.creating = false;
          if (createBrandPartnerBudgetsResponse.successful.length > 0) {
            this.getBrandPartner();
            this.unselectBudgetToEdit();
            this.budgetsChanged = true;
            this.notificationService.success("shared.changesSavedSuccessfully");
          } else if (createBrandPartnerBudgetsResponse.failed.length > 0) {
            switch (createBrandPartnerBudgetsResponse.failed[0].failedKey) {
              case CreateBrandPartnerBudgetFailedErrorKey.OverlappingTimeFrame:
                this.resetFormErrors();
                this.markDatesAsInvalid();
                this.notificationService.error(
                  "brand.partners.editBudget.overlappingTimeFrameError",
                );
                break;
              default:
                this.notificationService.error("shared.errorSavingTheChanges");
            }
          }
        },
        error: () => {
          this.creating = false;
          this.notificationService.error("shared.errorLoadingTheList");
        },
      });
  }

  public deleteBudgetConfirm(): void {
    this.showDeleteBudgetConfirmMessage = true;
  }

  public cancelDeleteBudget(): void {
    this.showDeleteBudgetConfirmMessage = false;
  }

  public deleteBudget(): void {
    this.showDeleteBudgetConfirmMessage = false;
    this.updatingOrDeleting = true;
    this.brandPartnerBudgetService
      .deleteBrandPartnerBudget(
        this.brandPartner.id,
        this.selectedBrandPartnerBudget,
      )
      .subscribe({
        next: () => {
          this.updatingOrDeleting = false;
          this.budgetsChanged = true;
          this.existingBudgetEditedOrDeleted = true;
          this.isEditingExistingBudget = false;
          this.getBrandPartner();
          this.initForm();
        },
        error: () => {
          this.updatingOrDeleting = false;
          this.notificationService.error("shared.errorSavingTheChanges");
        },
      });
  }

  public budgetValueChange(value: number): void {
    this.selectedBrandPartnerBudget.budget = value;
    this.form.controls.budget.setValue(value);
    this.existingBudgetEditedOrDeleted = false;
  }

  public handleCurrencyChange(value: CampaignCurrency): void {
    this.selectedBrandPartnerBudget.currency = value;
    this.currencySymbol =
      CampaignCurrencySymbol[this.selectedBrandPartnerBudget.currency];
  }

  public isEditingBudget(id: number): boolean {
    if (!this.selectedBrandPartnerBudget) {
      return false;
    }
    return this.selectedBrandPartnerBudget.id === id;
  }

  public showDeleteBudgetIcon(id: number): boolean {
    if (!this.selectedBrandPartnerBudget) {
      return false;
    }
    if (this.selectedBrandPartnerBudget.id !== id) {
      return false;
    }
    return !this.selectedBrandPartnerBudget.hasPartnerAssignedBudgetToCampaigns;
  }
}
