import { CommonModule } from "@angular/common";
import { inject, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSortModule } from "@angular/material/sort";
import { Router, RouterModule, Routes } from "@angular/router";
import { NgxUploaderModule } from "@angular-ex/uploader";
import { LandingPageProviderModule } from "../features/landing-page/landing-page.provider.module";
import { SharedProviderModule } from "../features/shared/shared-providers.module";
import { DateRangeSelectorComponent } from "../shared/components/date-range-selector/date-range-selector.component";
import { LoaderComponent } from "../shared/components/loader/loader.component";

import { SharedComponentsModule } from "../shared/components/shared-components.module";
import { AngularMaterialModule } from "../shared/material/material.module";
import { FormatCountryCodePipe } from "../shared/pipes/format-country-code.pipe";
import { SharedPipesModule } from "../shared/pipes/shared-pipes.module";

import { BrandPartnersComponent } from "./brand-partners/brand-partners.component";
import { BrandPartnersEditBudgetBulkDialogComponent } from "./brand-partners/brand-partners-edit-budget-bulk-dialog/brand-partners-edit-budget-bulk-dialog.component";
import { BrandPartnersEditBudgetDialogComponent } from "./brand-partners/brand-partners-edit-budget-dialog/brand-partners-edit-budget-dialog.component";
import { BrandSettingsComponent } from "./brand-settings/brand-settings.component";
import { BrandUploadLogoComponent } from "./brand-upload-logo/brand-upload-logo.component";
import { BrandUserManagementComponent } from "./brand-user-management/brand-user-management.component";
import { BrandDialogService } from "./brand-dialog.service";
import { RedirectToBrandIdBasedRouteGuard } from "./redirect-to-brand-id-based-route.guard";
import { BrandResolverGuard } from "./shared/brand-resolver.guard";
import { BrandPartnerService } from "./shared/services/brand-partner.service";

export const routes: Routes = [
  {
    path: "",
    canActivate: [RedirectToBrandIdBasedRouteGuard()],
    children: [
      { path: "settings", component: BrandSettingsComponent },
      { path: "partners", component: BrandPartnersComponent },
      {
        path: "landing-page",
        loadChildren: () =>
          import("../landing-page/landing-page.module").then(
            (m) => m.LandingPageModule,
          ),
      },
      {
        path: "campaigns",
        loadChildren: () =>
          import("./brand-campaign/brand-campaign.module").then(
            (m) => m.BrandCampaignModule,
          ),
      },

      // REDIRECTS
      {
        path: "campaigns-not-found",
        redirectTo: "campaigns/not-found",
        pathMatch: "full",
      },
      {
        path: "campaign",
        children: [
          {
            path: "**",
            children: [],
            canActivate: [
              () =>
                inject(Router).navigate([
                  new URL(location.href).pathname.replace(
                    "/campaign/",
                    "/campaigns/",
                  ),
                ]),
            ],
          },
        ],
      },
    ],
  },
  {
    path: ":brandId",
    canActivate: [BrandResolverGuard()],
    children: [
      { path: "", redirectTo: "campaigns", pathMatch: "full" },
      { path: "settings", component: BrandSettingsComponent },
      { path: "partners", component: BrandPartnersComponent },
      {
        path: "landing-page",
        loadChildren: () =>
          import("../landing-page/landing-page.module").then(
            (m) => m.LandingPageModule,
          ),
      },
      {
        path: "campaigns",
        loadChildren: () =>
          import("./brand-campaign/brand-campaign.module").then(
            (m) => m.BrandCampaignModule,
          ),
      },

      // REDIRECTS
      {
        path: "campaigns-not-found",
        redirectTo: "campaigns/not-found",
        pathMatch: "full",
      },
      {
        path: "campaign",
        children: [
          {
            path: "**",
            children: [],
            canActivate: [
              () =>
                inject(Router).navigate([
                  new URL(location.href).pathname.replace(
                    "/campaign/",
                    "/campaigns/",
                  ),
                ]),
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [
    BrandUserManagementComponent,
    BrandSettingsComponent,
    BrandUploadLogoComponent,
    BrandPartnersComponent,
    BrandPartnersEditBudgetDialogComponent,
    BrandPartnersEditBudgetBulkDialogComponent,
  ],
  imports: [
    DateRangeSelectorComponent,
    LandingPageProviderModule,
    ReactiveFormsModule,
    NgxUploaderModule,
    CommonModule,
    FormsModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedProviderModule,
    AngularMaterialModule,
    MatSortModule,
    RouterModule,
    RouterModule.forChild(routes),
    LoaderComponent,
  ],
  providers: [FormatCountryCodePipe, BrandDialogService, BrandPartnerService],
})
export class BrandModule {}
