import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ConfirmationDialogData } from "../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {
  CUSTOM_RANGE,
  type DateRangeSelectorOption,
} from "../../shared/components/date-range-selector/date-range-selector.component";
import { SlimBrandPartner } from "../../shared/models/SlimBrandPartner";
import { BrandService } from "../../shared/services/api/brand.service";
import {
  DateRange,
  DateRangePreset,
  DateService,
} from "../../shared/services/date.service";
import { DialogService } from "../../shared/services/dialog.service";
import { TableColumn } from "../../shared/services/filters/table-column";
import { TableColumnFilter } from "../../shared/services/filters/table-column-filter";
import {
  StorageKeys,
  LocalStorageService,
} from "../../shared/services/local-storage.service";
import { NotificationService } from "../../shared/services/notification.service";
import {
  GetBrandPartnersFiltersItem,
  GetBrandPartnersFiltersItemKey,
  GetBrandPartnersOrderBy,
  GetBrandPartnersOrderByDirection,
} from "../../shared/services/parameters/get-brand-partners-filters-item";
import { BrandPartnerListBudget } from "../../shared/services/responses/brand-partner-list-budgets";
import { BrandDialogService } from "../brand-dialog.service";
import { BrandPartnerService } from "../shared/services/brand-partner.service";

type SelectionMap = Map<number, boolean>;

@Component({
  selector: "app-brand-partners",
  templateUrl: "./brand-partners.component.html",
  styleUrls: ["./brand-partners.component.scss"],
})
export class BrandPartnersComponent implements OnInit, OnDestroy {
  private readonly SEARCH_PLACEHOLDER = "brand.partners.searchPlaceholder";

  @ViewChild("search") searchElement!: ElementRef;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  public partners: SlimBrandPartner[] = [];
  public dataSource!: MatTableDataSource<SlimBrandPartner>;
  public totalPartners = 0;
  public offset = 0;
  public limit = 50;
  public pageIndex = 0;
  public loading = false;
  public selectedPartners!: SelectionMap;
  public brandHasTimeFrameBudget = false;
  public filterTags: GetBrandPartnersFiltersItem[] = [];
  public filterDateRange: DateRange = this.date.getRange(
    DateRangePreset.CurrentYear,
  );
  public filterDateRangeOptions: DateRangeSelectorOption[] = [
    {
      icon: "calendar_month",
      key: Symbol("currentYear"),
      label: "shared.dateRangeSelector.currentYear",
      range: this.date.getRange(DateRangePreset.CurrentYear),
    },
    {
      icon: "calendar_month",
      key: Symbol("lastYear"),
      label: "shared.dateRangeSelector.lastYear",
      range: this.date.getRange(DateRangePreset.LastYear),
    },
    {
      icon: "open_in_full",
      key: Symbol("allTime"),
      label: "shared.dateRangeSelector.allTime",
      range: this.date.getRange(DateRangePreset.AllTime),
    },
    CUSTOM_RANGE,
  ];

  private inEdition = false;
  private readonly subs = new Subscription();
  private readonly timeFrameBudgetOnlyColumns: Set<string> = new Set([
    "displayBudget",
    "displayAssignedToCampaignsBudget",
    "displaySpentBudget",
  ]);

  private readonly allColumns: TableColumn[] = [
    new TableColumn("checkboxAction", undefined, true),
    new TableColumn("partnerId"),
    new TableColumn("partnerCompanyName"),
    new TableColumn("partnerStoreName"),
    new TableColumn("displayBudget"),
    new TableColumn("displayAssignedToCampaignsBudget"),
    new TableColumn("displaySpentBudget"),
    new TableColumn("totalCampaignsInvited"),
    new TableColumn("totalCampaignsAccepted"),
    new TableColumn("totalCampaignsEngaged"),
    new TableColumn("totalCampaignsCancelled"),
    new TableColumn("totalPublishedPosts"),
    new TableColumn("totalPublishedAds"),
    new TableColumn("totalAssetsDownloaded"),
    new TableColumn("totalViewsAllPosts"),
    new TableColumn("totalLikesAllPosts"),
    new TableColumn("totalCommentsAllPosts"),
    new TableColumn("totalSharesAllPosts"),
    new TableColumn("totalImpressions"),
    new TableColumn("totalReach"),
    new TableColumn("totalEngagement"),
    new TableColumn("totalLinkClicks"),
    new TableColumn("totalSpent"),
    new TableColumn("totalParticipants"),
    new TableColumn("totalPaidAds"),
    new TableColumn("totalPaidAdsBudget"),
    new TableColumn("actions", undefined, true),
  ];

  public displayedColumnsKeys: string[] = [
    "checkboxAction",
    "partnerId",
    "partnerCompanyName",
    "displayBudget",
    "totalPaidAds",
    "displayAssignedToCampaignsBudget",
    "displaySpentBudget",
    "totalCampaignsInvited",
    "totalCampaignsAccepted",
    "actions",
  ];

  private readonly perCampaignBudgetDefaultColumns: string[] = [
    "checkboxAction",
    "partnerId",
    "partnerCompanyName",
    "partnerStoreName",
    "totalCampaignsInvited",
    "totalCampaignsAccepted",
    "totalCampaignsEngaged",
    "totalCampaignsCancelled",
    "actions",
  ];

  public showColumnFilters = false;
  public availableColumnFilters: TableColumnFilter[] = [];
  public showHiddenPartners = false;
  public totalBudgets: BrandPartnerListBudget[] = [];
  public totalAssignedToCampaignsBudgets: BrandPartnerListBudget[] = [];
  public totalSpentBudgets: BrandPartnerListBudget[] = [];
  public totalHiddenPartners = 0;
  public showSearchField = false;
  public searchKey = "";
  public showSearchFilters = false;
  public filterOrderBy = GetBrandPartnersOrderBy.CompanyName;
  public filterOrderByOptions = GetBrandPartnersOrderBy;
  public filterOrderByDirection = GetBrandPartnersOrderByDirection.Asc;
  public filterOrderByDirections = GetBrandPartnersOrderByDirection;
  public latestSearches: string[] = [];
  public searchPlaceHolder? = this.SEARCH_PLACEHOLDER;

  constructor(
    protected readonly date: DateService,
    protected readonly dialog: MatDialog,
    protected readonly brandDialog: BrandDialogService,
    protected readonly brandService: BrandService,
    protected readonly brandPartnerService: BrandPartnerService,
    protected readonly notificationService: NotificationService,
    protected readonly translateService: TranslateService,
    protected readonly localStorageService: LocalStorageService,
    protected readonly dialogService: DialogService,
  ) {
    const searchHistory = this.localStorageService.get(
      StorageKeys.BrandPartnerSearchHistory,
    );

    if (searchHistory) {
      this.latestSearches = JSON.parse(searchHistory);
    }
  }

  public async ngOnInit(): Promise<void> {
    const brand = this.brandService.currentBrand;
    this.brandHasTimeFrameBudget = brand.hasTimeFrameBudget ?? false;

    const isColumnVisible = (column: string): boolean =>
      brand?.hasTimeFrameBudget || !this.timeFrameBudgetOnlyColumns.has(column);

    const cacheKeys = this.localStorageService.get(
      StorageKeys.BrandPartnerDisplayColumns,
    );

    if (cacheKeys) {
      const parsedKeys = JSON.parse(cacheKeys);
      if (parsedKeys) {
        this.displayedColumnsKeys = parsedKeys;
      }
    }

    this.displayedColumnsKeys = brand.hasTimeFrameBudget
      ? this.displayedColumnsKeys
      : this.perCampaignBudgetDefaultColumns;

    this.displayedColumnsKeys =
      this.displayedColumnsKeys.filter(isColumnVisible);

    this.availableColumnFilters = this.allColumns
      .filter((column) => !column.fixed && isColumnVisible(column.key))
      .map(
        (column) =>
          new TableColumnFilter(
            this.displayedColumnsKeys.includes(column.key),
            column.key,
          ),
      );

    this.getBrandPartners();
  }

  public ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  private getBrandPartners(): void {
    this.loading = true;
    this.brandPartnerService
      .getBrandPartners(
        this.offset,
        this.limit,
        this.showHiddenPartners,
        this.filterTags,
        this.filterDateRange.startDate,
        this.filterDateRange.endDate,
        this.filterOrderBy,
        this.filterOrderByDirection,
      )
      .subscribe({
        next: (brandPartnerList) => {
          this.partners = brandPartnerList.items;
          this.dataSource = new MatTableDataSource(brandPartnerList.items);
          this.totalPartners = brandPartnerList.totalCount;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.loading = false;
          this.totalBudgets = brandPartnerList.totalBudgets;
          this.totalAssignedToCampaignsBudgets =
            brandPartnerList.totalAssignedToCampaignsBudgets;
          this.totalSpentBudgets = brandPartnerList.totalSpentBudgets;
          this.totalHiddenPartners = brandPartnerList.totalHiddenPartners;
          this.hideEmptyHiddenPartnerList();
          this.selectedPartners = new Map(
            this.partners.map((partner) => [partner.id, false]),
          );
        },
        error: () => {
          this.loading = false;
          this.selectedPartners = new Map();
          this.notificationService.error("shared.errorLoadingTheList");
        },
      });
  }

  public async editPartnerBudget(
    slimBrandPartner: SlimBrandPartner,
  ): Promise<void> {
    const budgetChanged =
      await this.brandDialog.showBrandPartnersEditBudget(slimBrandPartner);

    if (budgetChanged) {
      this.getBrandPartners();
    }
  }

  public async editPartnersBulkBudget(): Promise<void> {
    const updated = await this.brandDialog.showBrandPartnersEditBudgetBulk(
      this.getSelectedPartners(),
    );

    if (updated) {
      this.clearSelectedPartners();
      this.getBrandPartners();
    }
  }

  public getSelectedPartners(): SlimBrandPartner[] {
    return this.partners.filter((partner) =>
      this.selectedPartners.get(partner.id),
    );
  }

  public onPageChange(event: any): void {
    this.offset = this.limit * event.pageIndex;
    this.pageIndex = event.pageIndex;
    this.getBrandPartners();
  }

  public isPartnerSelected(partnerId: number): boolean {
    return this.selectedPartners.get(partnerId) ?? false;
  }

  public areMultiplePartnersSelected(): boolean {
    let partnersSelected = 0;
    for (const isChecked of this.selectedPartners.values()) {
      partnersSelected += +isChecked;
      if (partnersSelected > 1) {
        return true;
      }
    }
    return false;
  }

  public areNoPartnersSelected(): boolean {
    for (const isChecked of this.selectedPartners.values()) {
      if (isChecked) {
        return false;
      }
    }
    return true;
  }

  public setAllPartnersSelection(checked: boolean): void {
    for (const partner of this.partners) {
      const selectable = this.showHiddenPartners ? partner.hidden : true;
      this.selectedPartners.set(partner.id, checked && selectable);
    }
  }

  public togglePartner(partner: SlimBrandPartner): void {
    this.selectedPartners.set(
      partner.id,
      !this.selectedPartners.get(partner.id),
    );
  }

  public selectOnlyPartner(partner: SlimBrandPartner): void {
    this.clearSelectedPartners();
    this.selectedPartners.set(partner.id, true);
  }

  public unselectPartner(partner: SlimBrandPartner): void {
    this.selectedPartners.set(partner.id, false);
  }

  public menuClosed(partner: SlimBrandPartner): void {
    if (!this.inEdition) {
      this.unselectPartner(partner);
    }
  }

  public getBudgetToolTip(partner: SlimBrandPartner): string {
    let text =
      this.translateService.instant(
        "brand.partners.budgetsMultipleCurrencies",
      ) + "\n";

    partner.differentCurrencyBudgets.forEach((budget) => {
      text = text + " \n" + budget.currency + ": " + budget.amount;
    });
    return text + "\n\n";
  }

  public getAssignedToCampaignsBudgetToolTip(
    partner: SlimBrandPartner,
  ): string {
    let text =
      this.translateService.instant(
        "brand.partners.budgetsMultipleCurrencies",
      ) + "\n";

    partner.differentCurrencyAssignedToCampaignsBudgets.forEach((budget) => {
      text = text + " \n" + budget.currency + ": " + budget.amount;
    });
    return text + "\n\n";
  }

  public getSpentBudgetToolTip(partner: SlimBrandPartner): string {
    let text =
      this.translateService.instant(
        "brand.partners.budgetsMultipleCurrencies",
      ) + "\n";

    partner.differentCurrencySpentBudgets.map((budget) => {
      text = text + " \n" + budget.currency + ": " + budget.amount;
    });
    return text + "\n\n";
  }

  public toggleColumn(column: TableColumnFilter): void {
    if (column.visible !== undefined) {
      column.visible = !column.visible;
    }

    this.displayedColumnsKeys = [
      "checkboxAction",
      ...this.availableColumnFilters
        .filter((col) => col.visible)
        .map((col) => col.value),
      "actions",
    ];

    this.localStorageService.store(
      StorageKeys.BrandPartnerDisplayColumns,
      JSON.stringify(this.displayedColumnsKeys),
    );
  }

  public hidePartner(partner: SlimBrandPartner): void {
    this.inEdition = true;
    this.confirmAction(
      {
        title: "brand.partners.hidePartnerConfirmTitle",
        message: "brand.partners.hidePartnerConfirmDescription",
        details: "brand.partners.hidePartnerConfirmSubDescription",
        confirmButton: "brand.partners.hidePartnerConfirmYes",
        cancelButton: "brand.partners.hidePartnerConfirmNo",
      },
      () => {
        partner.hidden = true;
        this.editPartners([partner]);
      },
    );
  }

  public showPartner(partner: SlimBrandPartner): void {
    this.inEdition = true;
    this.confirmAction(
      {
        title: "shared.confirmDialogTitle",
        message: "brand.partners.showPartnerConfirm",
      },
      () => {
        partner.hidden = false;
        this.editPartners([partner]);
      },
    );
  }

  public hidePartnersConfirm(): void {
    this.confirmAction(
      {
        title: "shared.confirmDialogTitle",
        message: "brand.partners.hidePartnersConfirm",
      },
      () => {
        const editedPartners = this.getSelectedPartners().map((partner) => {
          partner.hidden = true;
          return partner;
        });
        this.editPartners(editedPartners);
      },
    );
  }

  public showPartnersConfirm(): void {
    this.confirmAction(
      {
        title: "shared.confirmDialogTitle",
        message: "brand.partners.showPartnersConfirm",
      },
      () => {
        const editedPartners = this.getSelectedPartners().map((partner) => {
          partner.hidden = false;
          return partner;
        });
        this.editPartners(editedPartners);
      },
    );
  }

  private confirmAction(
    dialogConfig: ConfirmationDialogData,
    action: () => void,
  ): void {
    this.dialogService.confirm(dialogConfig).then((accepted) => {
      if (accepted) {
        action();
      }
      this.inEdition = false;
    });
  }

  private editPartners(partners: SlimBrandPartner[]): void {
    this.loading = true;
    this.brandPartnerService.updateBrandPartners(partners).subscribe(
      () => {
        this.clearSelectedPartners();
        this.getBrandPartners();
      },
      () => {
        this.loading = false;
        this.notificationService.error("shared.errorLoadingTheList");
      },
    );
  }

  public togglePartnerVisibility(): void {
    this.showHiddenPartners = !this.showHiddenPartners;
    this.getBrandPartners();
  }

  public toggleSearchField(): void {
    this.searchKey = "";
    this.showSearchField = !this.showSearchField;
    this.showSearchFilters = !this.showSearchFilters;

    if (this.showSearchField) {
      setTimeout(() => {
        // this will make the execution after the above boolean has changed
        this.searchElement?.nativeElement.focus();
        this.searchPlaceHolder = undefined;
      }, 150);
    }
  }

  public removeFilterTag(filterTag: GetBrandPartnersFiltersItem): void {
    this.filterTags = this.filterTags.filter(
      (tag) => tag.value !== filterTag.value,
    );
    this.getBrandPartners();
  }

  public addFilterTag(searchKey?: string): void {
    if (!searchKey && !this.searchKey) {
      return;
    }

    const key = searchKey ?? this.searchKey;
    const isFilterApplied = this.filterTags
      .map(({ value }) => value)
      .includes(key);

    if (!isFilterApplied) {
      this.filterTags.push(
        new GetBrandPartnersFiltersItem(
          GetBrandPartnersFiltersItemKey.CompanyName,
          key,
        ),
      );

      this.getBrandPartners();
    }

    // Update latest searches
    if (!this.latestSearches.includes(key)) {
      this.latestSearches.unshift(key);

      if (this.latestSearches.length > 3) {
        this.latestSearches.pop();
      }

      // Store latest searches
      this.localStorageService.store(
        StorageKeys.BrandPartnerSearchHistory,
        JSON.stringify(this.latestSearches),
      );
    }

    this.showSearchFilters = false;
    this.searchKey = "";
  }

  public setDateRangeFilter(range: DateRange): void {
    this.filterDateRange = range;
    this.getBrandPartners();
  }

  public searchFocusOut(): void {
    this.searchPlaceHolder = this.SEARCH_PLACEHOLDER;

    setTimeout(() => {
      this.showSearchField = false;
    }, 100);
  }

  private clearSelectedPartners(): void {
    for (const partnerId of this.selectedPartners.keys()) {
      this.selectedPartners.set(partnerId, false);
    }
  }

  public toggleOrderByDirection(filterOrderBy: GetBrandPartnersOrderBy): void {
    this.filterOrderBy = filterOrderBy;
    this.filterOrderByDirection =
      this.filterOrderByDirection === GetBrandPartnersOrderByDirection.Asc
        ? GetBrandPartnersOrderByDirection.Desc
        : GetBrandPartnersOrderByDirection.Asc;
    this.getBrandPartners();
  }

  public removeSearchPlaceHolder(): void {
    this.searchPlaceHolder = undefined;
  }

  public isSelectionDisabled(partner: SlimBrandPartner): boolean {
    return this.showHiddenPartners && !partner.hidden;
  }

  public isPartnerActionsDisabled(partner: SlimBrandPartner): boolean {
    return (
      this.areMultiplePartnersSelected() || this.isSelectionDisabled(partner)
    );
  }

  public isSelectAllIndeterminate(): boolean {
    return this.areMultiplePartnersSelected() && !this.areAllPartnersSelected();
  }

  public areAllPartnersSelected(): boolean {
    for (const partner of this.partners) {
      const isSelectable = this.showHiddenPartners ? partner.hidden : true;
      const isSelected = this.selectedPartners.get(partner.id);
      if (isSelectable && !isSelected) {
        return false;
      }
    }
    return true;
  }

  private hideEmptyHiddenPartnerList(): void {
    if (this.totalHiddenPartners === 0) {
      this.showHiddenPartners = false;
    }
  }
}
