<div class="loader-wrapper">
  <div class="partners-header">
    <div class="partners-header-title">
      {{ "brand.partners.title" | translate }}
    </div>
    <div class="partners-header-options"></div>
  </div>

  <section>
    <div class="table-header">
      <div class="table-header-left">
        <app-date-range-selector
          [options]="filterDateRangeOptions"
          [value]="filterDateRange"
          (rangeChange)="setDateRangeFilter($event)"
        />

        <!-- FILTER TAGS -->
        <div
          *ngIf="filterTags.length > 0"
          class="table-header-block table-header-block--margin-left"
        >
          <div class="table-header-block-search-tags">
            <div
              (click)="removeFilterTag(filterTag)"
              *ngFor="let filterTag of filterTags; let i = index"
              class="table-header-block-search-tags-item"
            >
              {{ filterTag.value }}
              <mat-icon>clear</mat-icon>
            </div>
          </div>
        </div>
        <!-- END OF FILTER TAGS -->

        <!-- SEARCH FILTER -->
        <div class="table-header-block table-header-block--margin-left">
          <mat-form-field
            (click)="removeSearchPlaceHolder()"
            class="table-header-block-search-field"
            subscriptSizing="dynamic"
            *ngIf="showSearchField"
          >
            <mat-icon matPrefix>search</mat-icon>
            <input
              #search
              (focusout)="searchFocusOut()"
              (keyup.enter)="addFilterTag()"
              matInput
              [(ngModel)]="searchKey"
              name="searchKey"
            />

            <!-- CLEAR SEARCH FILTER -->
            <mat-icon
              *ngIf="showSearchField"
              matSuffix
              (click)="showSearchField = false"
              >cancel
            </mat-icon>
          </mat-form-field>
          <div
            class="table-header-block-search-field-menu"
            *ngIf="showSearchField && latestSearches.length > 0"
          >
            <ul>
              <li class="table-header-block-search-field-menu-title">
                {{ "brand.partners.latestSearches" | translate }}
              </li>
              <ng-container
                *ngFor="let latestSearch of latestSearches; let i = index"
              >
                <li (click)="addFilterTag(latestSearch)">
                  <span>{{ latestSearch }}</span>
                  <mat-icon matPrefix>restore</mat-icon>
                </li>
              </ng-container>
            </ul>
          </div>
          <div
            *ngIf="!showSearchField"
            (click)="toggleSearchField()"
            class="table-header-block-search-text"
          >
            <mat-icon>search</mat-icon>
            <div>{{ "brand.partners.searchFilter" | translate }}</div>
          </div>
        </div>
        <!-- END OF SEARCH FILTER -->
      </div>
      <div class="table-header-right">
        <div class="table-header-block table-header-block--margin-right">
          <div
            (click)="togglePartnerVisibility()"
            class="table-header-block-button-hide-show"
            *ngIf="totalHiddenPartners > 0"
          >
            <p>
              {{ totalHiddenPartners }}
              {{ "brand.partners.hiddenPartners" | translate }}
            </p>
            <mat-icon
              class="material-icons-outlined table-header-block-button-hide-show-visibility"
              *ngIf="showHiddenPartners"
              >visibility
            </mat-icon>
            <mat-icon
              class="material-icons-outlined table-header-block-button-hide-show-visibility"
              *ngIf="!showHiddenPartners"
              >visibility_off
            </mat-icon>
          </div>
        </div>
        <div
          class="table-header-block"
          (clickOutside)="showColumnFilters = false"
        >
          <div
            (click)="showColumnFilters = !showColumnFilters"
            class="table-header-block-display-columns-button"
          >
            <p>{{ "shared.displayColumns" | translate }}</p>
            <div class="table-header-block-display-columns-button-arrow-down">
              <mat-icon class="material-icons-outlined">expand_more</mat-icon>
            </div>
          </div>
          <div
            [ngClass]="{ visible: showColumnFilters }"
            class="table-header-block-display-columns-menu"
          >
            <ul>
              <ng-container
                *ngFor="let column of availableColumnFilters; let i = index"
              >
                <li>
                  <mat-checkbox
                    name="display{{ column }}"
                    [checked]="column.visible"
                    (change)="toggleColumn(column)"
                  >
                    <span class="table-header-block-display-columns-menu-item">
                      {{
                        "brand.partners.listColumn." + column.value | translate
                      }}
                    </span>
                  </mat-checkbox>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="partners-list">
      <div class="partners-list-content">
        <div
          *ngIf="dataSource && !loading"
          class="partners-list-content-table-wrapper"
        >
          <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="checkboxAction">
              <th
                class="partners-list-content-select-all-wrap"
                mat-header-cell
                *matHeaderCellDef
              >
                <mat-checkbox
                  class="white"
                  name="select-all-brand-partners"
                  [indeterminate]="isSelectAllIndeterminate()"
                  [checked]="areAllPartnersSelected()"
                  (change)="setAllPartnersSelection($event.checked)"
                >
                </mat-checkbox>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                class="cell-checkbox"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                <mat-checkbox
                  name="select-brand-partner-{{ partner.id }}"
                  [checked]="isPartnerSelected(partner.id)"
                  [disabled]="isSelectionDisabled(partner)"
                  (change)="togglePartner(partner)"
                >
                </mat-checkbox>
              </td>
              <td class="cell-checkbox" mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="partnerCompanyName">
              <th mat-header-cell *matHeaderCellDef>
                <div
                  (click)="
                    toggleOrderByDirection(filterOrderByOptions.CompanyName)
                  "
                  class="partners-list-header--sortable"
                >
                  {{
                    "brand.partners.listColumn.partnerCompanyName" | translate
                  }}
                  <mat-icon
                    *ngIf="
                      filterOrderByDirection === filterOrderByDirections.Asc
                    "
                    >keyboard_arrow_down
                  </mat-icon>
                  <mat-icon
                    *ngIf="
                      filterOrderByDirection === filterOrderByDirections.Desc
                    "
                    >keyboard_arrow_up
                  </mat-icon>
                </div>
              </th>
              <td
                mat-cell
                class="mat-cell--extra-large"
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.partnerCompanyName }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="partnerStoreName">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{ "brand.partners.listColumn.partnerStoreName" | translate }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.partnerStoreName }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="partnerId">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{ "brand.partners.listColumn.partnerId" | translate }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.partnerId }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{ "brand.partners.listColumn.status" | translate }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.status }} {{ partner.id }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="displayAssignedToCampaignsBudget">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.displayAssignedToCampaignsBudget"
                      | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                <span
                  [ngClass]="{
                    'cell-highlighted': partner.hasBudgetsInDifferentCurrencies
                  }"
                >
                  {{ partner.displayAssignedToCampaignsBudget }}
                  {{ partner.displayCurrencySymbol }}
                  <mat-icon
                    matTooltipClass="cell-tooltip"
                    *ngIf="partner.hasBudgetsInDifferentCurrencies"
                    matTooltip="{{
                      getAssignedToCampaignsBudgetToolTip(partner)
                    }}"
                    >toll</mat-icon
                  >
                </span>
              </td>
              <td mat-footer-cell *matFooterCellDef>
                <div class="footer-summary-total">
                  {{ "brand.partner.footer.summaryTotal" | translate }}
                </div>
                <div
                  *ngFor="
                    let totalBudget of totalAssignedToCampaignsBudgets;
                    let i = index
                  "
                >
                  <div>
                    {{ totalBudget.amount }} {{ totalBudget.currencySymbol }}
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="displayBudget">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{ "brand.partners.listColumn.displayBudget" | translate }}
                </div>
              </th>
              <td
                mat-cell
                class="mat-cell--large"
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                <span
                  [ngClass]="{
                    'cell-highlighted': partner.hasBudgetsInDifferentCurrencies
                  }"
                >
                  {{ partner.displayBudget }}
                  {{ partner.displayCurrencySymbol }}
                  <mat-icon
                    matTooltipClass="cell-tooltip"
                    *ngIf="partner.hasBudgetsInDifferentCurrencies"
                    matTooltip="{{ getBudgetToolTip(partner) }}"
                    >toll</mat-icon
                  >
                </span>
              </td>
              <td mat-footer-cell *matFooterCellDef>
                <div class="footer-summary-total">
                  {{ "brand.partner.footer.summaryTotal" | translate }}
                </div>
                <div *ngFor="let totalBudget of totalBudgets; let i = index">
                  <div>
                    {{ totalBudget.amount }} {{ totalBudget.currencySymbol }}
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="displaySpentBudget">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.displaySpentBudget" | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                <span
                  [ngClass]="{
                    'cell-highlighted': partner.hasBudgetsInDifferentCurrencies
                  }"
                >
                  {{ partner.displaySpentBudget }}
                  {{ partner.displayCurrencySymbol }}
                  <mat-icon
                    matTooltipClass="cell-tooltip"
                    *ngIf="partner.hasBudgetsInDifferentCurrencies"
                    matTooltip="{{ getSpentBudgetToolTip(partner) }}"
                    >toll</mat-icon
                  >
                </span>
              </td>
              <td mat-footer-cell *matFooterCellDef>
                <div class="footer-summary-total">
                  {{ "brand.partner.footer.summaryTotal" | translate }}
                </div>
                <div
                  *ngFor="let totalBudget of totalSpentBudgets; let i = index"
                >
                  <div>
                    {{ totalBudget.amount }} {{ totalBudget.currencySymbol }}
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="totalCampaignsInvited">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.totalCampaignsInvited"
                      | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalCampaignsInvited }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="totalCampaignsAccepted">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.totalCampaignsAccepted"
                      | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalCampaignsAccepted }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="totalCampaignsEngaged">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.totalCampaignsEngaged"
                      | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalCampaignsEngaged }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="totalCampaignsCancelled">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.totalCampaignsCancelled"
                      | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalCampaignsCancelled }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Published Posts -->
            <ng-container matColumnDef="totalPublishedPosts">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.totalPublishedPosts" | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalPublishedPosts }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Published Ads -->
            <ng-container matColumnDef="totalPublishedAds">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.totalPublishedAds" | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalPublishedAds }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Assets Downloaded -->
            <ng-container matColumnDef="totalAssetsDownloaded">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.totalAssetsDownloaded"
                      | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalAssetsDownloaded }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Views All Posts -->
            <ng-container matColumnDef="totalViewsAllPosts">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.totalViewsAllPosts" | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalViewsAllPosts }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Likes All Posts -->
            <ng-container matColumnDef="totalLikesAllPosts">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.totalLikesAllPosts" | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalLikesAllPosts }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Comments All Posts -->
            <ng-container matColumnDef="totalCommentsAllPosts">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.totalCommentsAllPosts"
                      | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalCommentsAllPosts }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Shares All Posts -->
            <ng-container matColumnDef="totalSharesAllPosts">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.totalSharesAllPosts" | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalSharesAllPosts }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Impressions All Posts -->
            <ng-container matColumnDef="totalImpressions">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{ "brand.partners.listColumn.totalImpressions" | translate }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalImpressions }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Reach -->
            <ng-container matColumnDef="totalReach">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{ "brand.partners.listColumn.totalReach" | translate }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalReach }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Engagement -->
            <ng-container matColumnDef="totalEngagement">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{ "brand.partners.listColumn.totalEngagement" | translate }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalEngagement }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Link Clicks -->
            <ng-container matColumnDef="totalLinkClicks">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{ "brand.partners.listColumn.totalLinkClicks" | translate }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalLinkClicks }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Spent -->
            <ng-container matColumnDef="totalSpent">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{ "brand.partners.listColumn.totalSpent" | translate }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalSpent }}{{ partner.displayCurrencySymbol }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Participants -->
            <ng-container matColumnDef="totalParticipants">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.totalParticipants" | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalParticipants }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Paid Ads -->
            <ng-container matColumnDef="totalPaidAds">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{ "brand.partners.listColumn.totalPaidAds" | translate }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalPaidAds }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <!-- Total Paid Ads Budget -->
            <ng-container matColumnDef="totalPaidAdsBudget">
              <th mat-header-cell *matHeaderCellDef>
                <div class="header-title-wrapper">
                  {{
                    "brand.partners.listColumn.totalPaidAdsBudget" | translate
                  }}
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                {{ partner.totalPaidAdsBudget
                }}{{ partner.displayCurrencySymbol }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="mat-header-cell-actions"
              >
                <a
                  *ngIf="areNoPartnersSelected()"
                  class="partners-list-content-bulk-button partners-list-content-bulk-button--not-selectable"
                >
                  <mat-icon>more_horiz</mat-icon>
                </a>
                <a
                  *ngIf="areMultiplePartnersSelected()"
                  class="partners-list-content-bulk-button partners-list-content-bulk-button--selected"
                  [matMenuTriggerFor]="partnerBulkOptionsMenu"
                >
                  <mat-icon>more_horiz</mat-icon>
                </a>
                <mat-menu
                  class="actions-menu"
                  #partnerBulkOptionsMenu="matMenu"
                  xPosition="before"
                >
                  <ng-container *ngIf="areMultiplePartnersSelected()">
                    <button
                      *ngIf="brandHasTimeFrameBudget"
                      mat-menu-item
                      (click)="editPartnersBulkBudget()"
                      [disabled]="showHiddenPartners"
                    >
                      <mat-icon class="partners-list-content-action-icon"
                        >attach_money
                      </mat-icon>
                      {{ "brand.partners.option.newBudget" | translate }}
                    </button>
                    <button
                      mat-menu-item
                      (click)="hidePartnersConfirm()"
                      *ngIf="!showHiddenPartners"
                    >
                      <mat-icon class="partners-list-content-action-icon"
                        >visibility
                      </mat-icon>
                      {{ "brand.partners.option.hidePartners" | translate }}
                    </button>
                    <button
                      mat-menu-item
                      (click)="showPartnersConfirm()"
                      *ngIf="showHiddenPartners"
                    >
                      <mat-icon class="partners-list-content-action-icon"
                        >visibility
                      </mat-icon>
                      {{ "brand.partners.option.showPartners" | translate }}
                    </button>
                  </ng-container>
                </mat-menu>
              </th>
              <td
                mat-cell
                *matCellDef="let partner"
                class="mat-cell-actions"
                [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
              >
                <div class="mat-column-actions">
                  <button
                    [disabled]="isPartnerActionsDisabled(partner)"
                    class="options"
                    mat-button
                    [matMenuTriggerFor]="partnerOptionsMenu"
                    (menuOpened)="selectOnlyPartner(partner)"
                    (menuClosed)="menuClosed(partner)"
                  >
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                  <mat-menu
                    class="actions-menu"
                    #partnerOptionsMenu="matMenu"
                    xPosition="before"
                  >
                    <button
                      mat-menu-item
                      *ngIf="brandHasTimeFrameBudget"
                      (click)="editPartnerBudget(partner)"
                      [disabled]="showHiddenPartners"
                    >
                      <mat-icon class="partners-list-content-action-icon"
                        >attach_money
                      </mat-icon>
                      {{ "brand.partners.option.budget" | translate }}
                    </button>
                    <button
                      *ngIf="!partner.hidden"
                      mat-menu-item
                      (click)="hidePartner(partner)"
                    >
                      <mat-icon class="partners-list-content-action-icon"
                        >visibility_off
                      </mat-icon>
                      {{ "brand.partners.option.hidePartner" | translate }}
                    </button>
                    <button
                      *ngIf="partner.hidden"
                      mat-menu-item
                      (click)="showPartner(partner)"
                    >
                      <mat-icon class="partners-list-content-action-icon"
                        >visibility
                      </mat-icon>
                      {{ "brand.partners.option.showPartner" | translate }}
                    </button>
                  </mat-menu>
                </div>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsKeys"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsKeys"
            ></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumnsKeys"></tr>
          </table>
          <table *ngIf="totalPartners === 0" class="no-results-table">
            <tr>
              <td class="no-results-table-td">
                {{ "shared.noResultsFound" | translate }}
              </td>
            </tr>
          </table>
        </div>
        <app-page-loader
          *ngIf="loading"
          [text]="'shared.loading' | translate"
        ></app-page-loader>

        <div *ngIf="!loading" class="table-pagination-footer">
          <mat-paginator
            #paginator
            class="paginator--centered"
            [length]="totalPartners"
            [pageSize]="limit"
            [pageIndex]="pageIndex"
            [hidePageSize]="true"
            (page)="onPageChange($event)"
          ></mat-paginator>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #simpleCol let-param="param">
  <ng-container [matColumnDef]="param">
    <th mat-header-cell *matHeaderCellDef>
      <div class="header-title-wrapper">
        {{ "brand.partners.listColumn." + param | translate }}
      </div>
    </th>
    <td
      mat-cell
      *matCellDef="let partner"
      [ngClass]="{ 'mat-cell--hidden': partner.hidden }"
    >
      {{ partner[param] }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
</ng-template>
