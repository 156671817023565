import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import isEqual from "lodash/isEqual";
import { DateRange, DateService } from "../../services/date.service";

const CUSTOM = Symbol("custom");

export interface DateRangeSelectorOption {
  icon: string;
  key: symbol;
  label: string;
  range: DateRange;
}

export const CUSTOM_RANGE: DateRangeSelectorOption = {
  icon: "edit_calendar",
  key: CUSTOM,
  label: "shared.dateRangeSelector.customTimeFrame",
  range: { startDate: new Date(0), endDate: new Date(0) },
};

export interface DateRangePretty {
  start: string;
  end: string;
}

@Component({
  standalone: true,
  selector: "app-date-range-selector",
  templateUrl: "./date-range-selector.component.html",
  styleUrls: ["./date-range-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class DateRangeSelectorComponent implements OnChanges {
  @Input({ required: true }) public value!: DateRange;
  @Input({ required: true }) public options!: DateRangeSelectorOption[];

  @Output() public rangeChange = new EventEmitter<DateRange>();

  protected activeFilter!: symbol;
  protected label!: string;
  protected optionsMap!: Record<symbol, DateRangeSelectorOption>;
  protected showCustomDates = false;
  protected showMenu = false;
  public customRange!: FormGroup<{
    startDate: FormControl<Date>;
    endDate: FormControl<Date>;
  }>;

  constructor(
    private readonly date: DateService,
    private readonly fb: NonNullableFormBuilder,
  ) {}

  public ngOnChanges(): void {
    this.customRange = this.fb.group({
      startDate: this.value.startDate,
      endDate: this.value.endDate,
    });

    this.activeFilter = this.guessRangeOption(this.options, this.value);
    this.optionsMap = Object.fromEntries(
      this.options.map((option) => [option.key, option]),
    );

    this.updateLabel();
  }

  protected applyCustomFilter(): void {
    this.activeFilter = CUSTOM;
    this.showCustomDates = false;
    this.updateLabel();

    this.value = this.date.removeRangeTimeZone(this.customRange.getRawValue());
    this.emit();
  }

  protected closeMenus(): void {
    this.showMenu = false;
    this.showCustomDates = false;
  }

  private emit(): void {
    this.rangeChange.emit(this.value);
  }

  protected getPrettyRange(): DateRangePretty {
    return {
      start: this.date.formatDate(this.value.startDate),
      end: this.date.formatDate(this.value.endDate),
    };
  }

  private guessRangeOption(
    options: DateRangeSelectorOption[],
    range: DateRange,
  ): symbol {
    for (const option of options) {
      if (isEqual(range, option.range)) {
        return option.key;
      }
    }

    return CUSTOM;
  }

  protected setDateFilters(activeFilter: symbol): void {
    this.showMenu = false;

    if (activeFilter !== CUSTOM) {
      this.value = this.optionsMap[activeFilter].range;
      this.customRange.setValue(this.value);
      this.activeFilter = activeFilter;
      this.updateLabel();
      this.emit();
    } else {
      this.showCustomDates = true;
    }
  }

  protected toggleMenus(): void {
    if (this.showCustomDates) {
      this.showCustomDates = !this.showCustomDates;
    } else {
      this.showMenu = !this.showMenu;
    }
  }

  private updateLabel(): void {
    if (this.activeFilter !== CUSTOM) {
      this.label = this.optionsMap[this.activeFilter].label;
    } else {
      this.label = "shared.dateRangeSelector.customRangeLabel";
    }
  }
}
