import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";

import { Brand } from "../../features/brand/domain/models/brand";
import { User } from "../../shared/models/user";
import { AuthenticationService } from "../../shared/services/api/authentication.service";
import { UserService } from "../../shared/services/api/user.service";
import { NotificationService } from "../../shared/services/notification.service";
import { DialogService } from "../../shared/services/dialog.service";

@Component({
  selector: "app-brand-user-management",
  templateUrl: "./brand-user-management.component.html",
  styleUrl: "./brand-user-management.component.scss",
})
export class BrandUserManagementComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public brand!: Brand;

  @ViewChild(NgForm) protected umForm!: NgForm;

  protected users: User[] = [];
  protected userEmail = "";
  protected loggedInUserName: string;
  protected saving = false;

  private readonly subscription = new Subscription();

  constructor(
    private readonly dialogService: DialogService,
    private userService: UserService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
  ) {
    this.loggedInUserName = this.authenticationService.getUserName() ?? "";
  }

  public ngOnInit(): void {
    this.getBrandUsers();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getBrandUsers(): void {
    if (!this.brand) {
      return;
    }

    this.userService.getBrandUsers(this.brand.id).subscribe({
      next: (users) => {
        this.users = users;
      },
      error: () => {
        this.notificationService.error(
          "brand.userManagement.errorLoadingBrandUsers",
        );
      },
    });
  }

  private clearForm(): void {
    this.umForm.resetForm();
  }

  protected addUser(email: string): void {
    this.saving = true;

    this.userService.createBrandUser(this.brand.id, email).subscribe({
      next: (user) => {
        this.users.push(user);
        this.notificationService.info("brand.userManagement.userAdded");
        this.clearForm();
        this.saving = false;
      },
      error: (e) => {
        const errorKey = e?.error?.error?.key;
        if (errorKey === "USER_EXISTS") {
          this.saving = false;
          this.notificationService.error(
            "brand.userManagement.userAlreadyExists",
          );
        } else if (errorKey === "USER_NOT_FOUND") {
          this.inviteBrandUser(email);
        }
      },
    });
  }

  private inviteBrandUser(email: string): void {
    this.userService.inviteBrandUser(this.brand.id, email).subscribe({
      next: (user: User) => {
        this.users.push(user);
        this.clearForm();
        this.saving = false;
        this.notificationService.success("brand.userManagement.userInvited");
      },
      error: () => {
        this.saving = false;
        this.notificationService.error(
          "brand.userManagement.errorInvitingUser",
        );
      },
    });
  }

  private removeUser(user: User): void {
    this.userService.deleteBrandUser(this.brand.id, user.id).subscribe({
      next: () => {
        this.getBrandUsers();
        this.notificationService.info("brand.userManagement.userRemoved");
      },
      error: () => {
        this.notificationService.error(
          "brand.userManagement.errorRemovingUser",
        );
      },
    });
  }

  protected async confirmRemoval(user: User): Promise<void> {
    const confirmed = await this.dialogService.showConfirmRemoveUser(
      user.email,
    );

    if (confirmed) {
      this.removeUser(user);
    }
  }
}
