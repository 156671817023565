import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { lastValueFrom } from "rxjs";
import { BrandService } from "../shared/services/api/brand.service";
import { AuthenticationService } from "../shared/services/api/authentication.service";
import { AdminUrl } from "../admin/admin.url";
import { getUrlWithId } from "../shared/utils/get-url-with-id";

export const RedirectToBrandIdBasedRouteGuard =
  (): CanActivateFn =>
  async (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> => {
    const NOT_FOUND = "/not-found";
    const authService = inject(AuthenticationService);
    const brandService = inject(BrandService);
    const router = inject(Router);
    const lastSelectedBrandId = brandService.lastSelectedBrandId;
    const url = state.url;

    if (lastSelectedBrandId) {
      router.navigate([
        getUrlWithId(url, "brand", lastSelectedBrandId) ?? NOT_FOUND,
      ]);
    } else if (authService.isRoleBrand()) {
      const brands = await lastValueFrom(brandService.getBrands());

      if (brands.length) {
        router.navigate([
          getUrlWithId(url, "brand", brands[0].id) ?? NOT_FOUND,
        ]);
      } else {
        router.navigate([NOT_FOUND]);
      }
    } else if (authService.isRoleAdmin()) {
      router.navigate([AdminUrl.Home()]);
    } else {
      router.navigate([NOT_FOUND]);
    }

    return false;
  };
