<section class="brand-users">
  <div>
    <div class="brand-users-title">
      {{ "brand.userManagement.title" | translate }}
    </div>
    <div class="brand-users-intro">
      {{ "brand.userManagement.selectedUsersMustHaveValidAccount" | translate }}
    </div>
    <ul class="brand-users-list">
      <li class="brand-users-list-item" *ngFor="let user of users">
        <button
          class="brand-users-list-item-button-delete"
          type="button"
          [disabled]="user.email === loggedInUserName"
          (click)="confirmRemoval(user)"
        >
          {{ user.email }}
          <span *ngIf="user.email === loggedInUserName"
            >(<ng-container>{{
              "brand.userManagement.yourself" | translate
            }}</ng-container
            >)</span
          >
          <span *ngIf="user.email !== loggedInUserName"
            >({{ user.status }})</span
          >
        </button>
      </li>
    </ul>
    <form
      class="brand-users-form"
      name="user-management-form"
      (ngSubmit)="umForm.form.valid && addUser(usrEmail.value)"
      #umForm="ngForm"
      novalidate
    >
      <mat-form-field class="fieldset">
        <mat-label>{{ "shared.emailAddress" | translate }}</mat-label>
        <input
          matInput
          #usrEmail="ngModel"
          [(ngModel)]="userEmail"
          name="usrEmail"
          type="email"
          email
          required
        />
        <mat-error *ngIf="usrEmail.errors && usrEmail.errors['required']">
          {{ "shared.emailAddressIsRequired" | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            usrEmail.errors &&
            usrEmail.errors['email'] &&
            !usrEmail.errors.required
          "
        >
          {{ "shared.enterAValidEmailAddress" | translate }}
        </mat-error>
      </mat-form-field>
      <button
        [disabled]="!brand || !userEmail || saving"
        class="round-button round-button--orange brand-users-form-button"
      >
        <span *ngIf="saving">{{ "shared.saving" | translate }}</span>
        <span *ngIf="!saving">{{
          "brand.userManagement.addUser" | translate
        }}</span>
      </button>
    </form>
  </div>
</section>
