import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import dayjs from "dayjs";

import {
  CampaignCurrency,
  CampaignCurrencySymbol,
} from "../../../shared/enums/campaign.enums";
import { SlimBrandPartner } from "../../../shared/models/SlimBrandPartner";
import { BrandPartnerBudgetService } from "../../brand-campaign/shared/services/brand-partner-budget.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { CreateBrandPartnerBudgetItemData } from "../../../shared/services/parameters/create-brand-partner-budget-item-data";
import { CreateBrandPartnerBudgetsData } from "../../../shared/services/parameters/create-brand-partner-budgets-data";
import { CreateBrandPartnerBudgetFailedResponse } from "../../../shared/services/responses/create-brand-partner-budget-failed-response";
import { CreateBrandPartnerBudgetsResponse } from "../../../shared/services/responses/create-brand-partner-budgets-response";

export type BrandPartnersEditBudgetBulkDialogData = SlimBrandPartner[];
export type BrandPartnersEditBudgetBulkDialogResult = true;

@Component({
  selector: "app-brand-partners-edit-budget-bulk-dialog",
  templateUrl: "./brand-partners-edit-budget-bulk-dialog.component.html",
  styleUrls: ["./brand-partners-edit-budget-bulk-dialog.component.scss"],
})
export class BrandPartnersEditBudgetBulkDialogComponent implements OnInit {
  public readonly MAX_DATE = dayjs().add(2, "years").toDate();
  public readonly MIN_DATE = dayjs().subtract(2, "years").toDate();
  public readonly CURRENCIES = Object.values(CampaignCurrency);

  public slimBrandPartners: SlimBrandPartner[];
  public maxBudget = 10000;
  public fromDate = new Date();
  public currencySymbol: CampaignCurrencySymbol = CampaignCurrencySymbol.EUR;
  public creating = false;
  public validating = false;
  public partnerErrors: CreateBrandPartnerBudgetFailedResponse[] = [];
  public readonly form: UntypedFormGroup;

  private toDate = dayjs().add(1, "month").toDate();
  private budget = 0;
  private currency = CampaignCurrency.EUR;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: BrandPartnersEditBudgetBulkDialogData,
    private readonly dialogRef: MatDialogRef<
      BrandPartnersEditBudgetBulkDialogComponent,
      BrandPartnersEditBudgetBulkDialogResult
    >,
    private readonly brandPartnerBudgetService: BrandPartnerBudgetService,
    private readonly notificationService: NotificationService,
  ) {
    this.slimBrandPartners = data;

    this.form = new UntypedFormGroup({
      fromDate: new UntypedFormControl({
        value: this.fromDate,
        disabled: false,
      }),
      toDate: new UntypedFormControl({
        value: this.toDate,
        disabled: false,
      }),
      currency: new UntypedFormControl({
        value: this.currency,
        disabled: false,
      }),
      budget: new UntypedFormControl({
        value: this.budget,
        disabled: false,
      }),
    });
  }

  public ngOnInit(): void {
    this.validateBudget();
  }

  public get partnerList(): string {
    return this.slimBrandPartners.map((p) => p.partnerCompanyName).join(", ");
  }

  public validateAndUpdateStartDate(value: dayjs.Dayjs): void {
    this.fromDate = value.toDate();
    if (this.toDate < this.fromDate) {
      this.toDate = dayjs(this.fromDate).add(1, "month").toDate();
      this.form.controls.toDate.setValue(this.toDate);
    }
    this.validateBudget();
  }

  public validateAndUpdateEndDate(value: dayjs.Dayjs): void {
    this.toDate = value.toDate();
    this.validateBudget();
  }

  public isSaveDisabled(): boolean {
    if (this.creating) {
      return true;
    }
    return this.budget === 0;
  }

  public validateBudget(): void {
    if (this.budget === 0) {
      return;
    }

    const budgets: CreateBrandPartnerBudgetItemData[] = [];
    this.slimBrandPartners.map((sp: SlimBrandPartner) => {
      budgets.push(
        new CreateBrandPartnerBudgetItemData(
          sp.id,
          sp.partnerCompanyName,
          this.fromDate,
          this.toDate,
          this.budget,
          this.currency,
        ),
      );
    });

    this.validating = true;
    this.brandPartnerBudgetService
      .validateBrandPartnerBudgets(new CreateBrandPartnerBudgetsData(budgets))
      .subscribe(
        (
          createBrandPartnerBudgetsResponse: CreateBrandPartnerBudgetsResponse,
        ) => {
          this.validating = false;
          this.partnerErrors = createBrandPartnerBudgetsResponse.failed;
        },
        () => {
          this.validating = false;
          this.notificationService.error("shared.errorLoadingTheList");
        },
      );
  }

  public saveBudget(): void {
    const budgets: CreateBrandPartnerBudgetItemData[] = [];
    this.slimBrandPartners.map((sp: SlimBrandPartner) => {
      budgets.push(
        new CreateBrandPartnerBudgetItemData(
          sp.id,
          sp.partnerCompanyName,
          this.fromDate,
          this.toDate,
          this.budget,
          this.currency,
        ),
      );
    });

    this.creating = true;
    this.brandPartnerBudgetService
      .createBrandPartnerBudgets(new CreateBrandPartnerBudgetsData(budgets))
      .subscribe({
        next: (
          createBrandPartnerBudgetsResponse: CreateBrandPartnerBudgetsResponse,
        ) => {
          this.creating = false;
          this.partnerErrors = createBrandPartnerBudgetsResponse.failed;
          this.dialogRef.close(true);
          this.notificationService.success("shared.changesSavedSuccessfully");
        },
        error: () => {
          this.creating = false;
          this.notificationService.error("shared.errorLoadingTheList");
        },
      });
  }

  public budgetValueChange(value: number): void {
    this.budget = value;
    this.form.controls.budget.setValue(value);
  }

  public handleCurrencyChange(value: CampaignCurrency): void {
    this.currency = value;
    this.currencySymbol = CampaignCurrencySymbol[this.currency];
  }
}
