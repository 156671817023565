<div
  class="date-range-backdrop"
  *ngIf="showMenu || showCustomDates"
  (click)="closeMenus()"
></div>
<div class="date-range-picker">
  <div (click)="toggleMenus()" class="date-range-picker__label">
    <mat-icon>{{ optionsMap[this.activeFilter].icon }}</mat-icon>
    {{ label | translate: getPrettyRange() }}
  </div>
  <ul [ngClass]="{ hidden: !showMenu }" class="date-range-picker__filter-menu">
    <li *ngFor="let option of options" (click)="setDateFilters(option.key)">
      <mat-icon>{{ option.icon }}</mat-icon>
      {{ option.label | translate }}
    </li>
  </ul>
  <div class="dropdown-menu" [ngClass]="{ hidden: !showCustomDates }">
    <div class="dropdown-menu__item">
      <mat-form-field>
        <mat-label
          translate="shared.dateRangeSelector.customRangeInputLabel"
        ></mat-label>
        <mat-date-range-input
          [rangePicker]="dateRangePicker"
          [formGroup]="customRange"
        >
          <input
            readonly
            matStartDate
            formControlName="startDate"
            placeholder="{{ 'shared.startDate' | translate }}"
          />
          <input
            readonly
            matEndDate
            formControlName="endDate"
            placeholder="{{ 'shared.endDate' | translate }}"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matSuffix
          [for]="dateRangePicker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <button
      (click)="applyCustomFilter()"
      class="btn btn--primary dropdown-menu__submit"
    >
      <span>{{ "shared.apply" | translate }}</span>
    </button>
  </div>
</div>
