<ng-container>
  <h2 mat-dialog-title>
    {{ "brand.partners.createBulkBudget.title" | translate }}
  </h2>
  <span class="close-icon" [mat-dialog-close]="">
    <mat-icon>close</mat-icon>
  </span>
  <mat-dialog-content>
    <mat-progress-bar
      *ngIf="validating"
      [color]="'accent'"
      class="progress-bar"
      mode="indeterminate"
    ></mat-progress-bar>
    <form [formGroup]="form">
      <div class="assign-budget-partners">
        {{ "brand.partners.createBulkBudget.editBudgetFor" | translate }}
        <span *ngFor="let slimBrandPartner of slimBrandPartners; let i = index">
          {{ slimBrandPartner.partnerCompanyName
          }}<span *ngIf="slimBrandPartners.length - 1 > i">,</span>
        </span>
      </div>
      <div class="assign-budget-section">
        <div class="assign-budget-section-title">
          {{ "brand.partners.createBulkBudget.selectDates" | translate }}
        </div>
        <div class="assign-budget-section-dates">
          <div class="assign-budget-section-dates-item">
            <mat-form-field class="mo-auto-width">
              <mat-label>{{ "shared.startDate" | translate }}</mat-label>
              <input
                matInput
                formControlName="fromDate"
                (dateChange)="validateAndUpdateStartDate($event.value)"
                [min]="MIN_DATE | dayjsTransform"
                [matDatepicker]="fromDatePicker"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fromDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #fromDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="assign-budget-section-dates-item">
            <mat-form-field class="mo-auto-width">
              <mat-label>{{ "shared.endDate" | translate }}</mat-label>
              <input
                matInput
                formControlName="toDate"
                (dateChange)="validateAndUpdateEndDate($event.value)"
                [min]="fromDate | dayjsTransform"
                [max]="MAX_DATE | dayjsTransform"
                [matDatepicker]="toDatePicker"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="toDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #toDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="assign-budget-section-currency">
          <div class="assign-budget-section-title">
            {{ "brand.partners.createBulkBudget.selectCurrency" | translate }}
          </div>
          <mat-form-field>
            <mat-select
              name="currency"
              formControlName="currency"
              (selectionChange)="handleCurrencyChange($event.value)"
            >
              <mat-option
                *ngFor="let currency of CURRENCIES"
                [value]="currency"
                >{{ currency }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="assign-budget-section-amount">
          <div class="assign-budget-section-title">
            {{ "brand.partners.createBulkBudget.selectAmount" | translate }}
          </div>

          <div class="assign-budget-section-amount-slider">
            <mat-slider
              step="1"
              name="adDuration"
              class="assign-budget-section-amount-slider-bar"
              [max]="maxBudget"
              [min]="0"
              #ngSlider
              ><input
                formControlName="budget"
                matSliderThumb
                (input)="
                  budgetValueChange(
                    {
                      source: ngSliderThumb,
                      parent: ngSlider,
                      value: ngSliderThumb.value
                    }.value
                  )
                "
                #ngSliderThumb="matSliderThumb"
                (change)="validateBudget()"
              />
            </mat-slider>
            <div class="assign-budget-section-amount-value">
              <input
                (input)="budgetValueChange($event.target.value)"
                matInput
                min="0"
                max="{{ maxBudget }}"
                formControlName="budget"
                name="adDurationInput"
                type="number"
                required
              />
              <span>{{ currencySymbol }}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="partnerErrors.length > 0" class="assign-budget-errors">
        <div class="assign-budget-errors-icon">
          <span class="material-icons"> warning_amber </span>
        </div>
        <div class="assign-budget-errors-title">
          {{
            "brand.partners.createBulkBudget.partnerErrorsTitle"
              | translate: { partnersNameList: partnerList }
          }}
        </div>
      </div>
    </form>
  </mat-dialog-content>
</ng-container>
<mat-dialog-actions>
  <button class="btn btn--transparent" [mat-dialog-close]="">
    {{ "shared.cancel" | translate }}
  </button>
  <button
    [disabled]="isSaveDisabled()"
    class="btn btn--primary"
    (click)="saveBudget()"
  >
    <span *ngIf="!creating">{{ "shared.save" | translate }}</span>
    <span *ngIf="creating">{{ "shared.saving" | translate }}</span>
  </button>
</mat-dialog-actions>
