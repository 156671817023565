import { Injectable } from "@angular/core";
import { CommonDialogService } from "../shared/services/common-dialog.service";
import {
  BrandPartnersEditBudgetBulkDialogComponent,
  BrandPartnersEditBudgetBulkDialogData,
  BrandPartnersEditBudgetBulkDialogResult,
} from "./brand-partners/brand-partners-edit-budget-bulk-dialog/brand-partners-edit-budget-bulk-dialog.component";
import {
  BrandPartnersEditBudgetDialogComponent,
  BrandPartnersEditBudgetDialogData,
  BrandPartnersEditBudgetDialogResult,
} from "./brand-partners/brand-partners-edit-budget-dialog/brand-partners-edit-budget-dialog.component";

@Injectable()
export class BrandDialogService {
  constructor(private dialog: CommonDialogService) {}

  public readonly showBrandPartnersEditBudgetBulk =
    this.dialog.createOpenDialogFn<
      BrandPartnersEditBudgetBulkDialogData,
      BrandPartnersEditBudgetBulkDialogResult
    >(BrandPartnersEditBudgetBulkDialogComponent);

  public readonly showBrandPartnersEditBudget = this.dialog.createOpenDialogFn<
    BrandPartnersEditBudgetDialogData,
    BrandPartnersEditBudgetDialogResult
  >(
    BrandPartnersEditBudgetDialogComponent,
    BrandPartnersEditBudgetDialogComponent.getConfig(),
  );
}
