import { CampaignCurrency } from "../enums/campaign.enums";
import { SerializableObject } from "./serializable-object";

export interface CurrencyBudget {
  readonly amount: number;
  readonly currency: CampaignCurrency;
}

export class SlimBrandPartner extends SerializableObject {
  id!: number;
  partnerId!: number;
  partnerCompanyName!: string;
  displayCurrencySymbol!: string;
  displayBudget!: number;
  displayAssignedToCampaignsBudget!: number;
  displaySpentBudget!: number;
  hasBudgetsInDifferentCurrencies!: boolean;

  differentCurrencyBudgets!: CurrencyBudget[];
  differentCurrencyAssignedToCampaignsBudgets!: CurrencyBudget[];
  differentCurrencySpentBudgets!: CurrencyBudget[];

  totalCampaignsInvited!: number;
  totalCampaignsAccepted!: number;
  hidden!: boolean;

  protected coercePropertyType(propertyName: string, propertyValue: any) {
    switch (propertyName) {
      default:
        return propertyValue;
    }
  }
}
