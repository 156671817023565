import { Component, Input } from "@angular/core";
import {
  BrandAddressEntityToBrandAddressMapper,
  BrandEntityToBrandMapper,
} from "../../features/brand/data/mappers/brand.mapper";
import { BrandService } from "../../shared/services/api/brand.service";
import { Brand } from "../../features/brand/domain/models/brand";
import { DialogService } from "../../shared/services/dialog.service";
import { NotificationService } from "../../shared/services/notification.service";
import { DefaultImageUploadMaxFileSize } from "../../shared/defaults/file-uploader-max-file-size";
import { TranslateService } from "@ngx-translate/core";
import { UploaderResponseError } from "../../shared/components/file-uploader/models/uploader-response-error";
import { AnyToImageEntityMapper } from "../../shared/mappers/image.entity.mapper";

@Component({
  selector: "app-brand-upload-logo",
  templateUrl: "./brand-upload-logo.component.html",
  styleUrl: "./brand-upload-logo.component.scss",
})
export class BrandUploadLogoComponent {
  @Input({ required: true }) public brand!: Brand;

  protected readonly AnyToImageEntityMapper = AnyToImageEntityMapper;
  protected readonly MaxFileSize = DefaultImageUploadMaxFileSize;
  protected brandUploadLogoUrl: string;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly brandService: BrandService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {
    this.brandUploadLogoUrl = this.brandService.getUploadBrandLogoUrl();
  }

  protected handleResponse(brand: unknown): void {
    const brandMapper = new BrandEntityToBrandMapper(
      new BrandAddressEntityToBrandAddressMapper(),
    );
    this.brand = brandMapper.map(brand);
    this.notificationService.success("shared.changesSavedSuccessfully");
  }

  protected deleteLogoConfirm(): void {
    this.dialogService.confirm({
      title: "shared.confirmDialogTitle",
      message: "brand.settings.uploadLogo.deleteConfirm",
      onConfirm: () => this.deleteLogo(),
    });
  }

  private deleteLogo(): void {
    this.brandService.deleteBrandLogo().subscribe({
      next: () => {
        this.brand.logo = null as any;
      },
      error: () => {
        this.notificationService.error("shared.changesCouldNotBeSaved");
      },
    });
  }

  protected fileUploaderErrorHandler(error: UploaderResponseError): void {
    this.notificationService.error(
      this.translateService.instant(error.message, error.interpolateParams),
    );
  }
}
