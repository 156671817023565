<ng-container>
  <span class="close-icon" (click)="close()">
    <mat-icon>close</mat-icon>
  </span>
  <div class="dialog-title">
    <h2 mat-dialog-title>
      {{
        "brand.partners.editBudget.title"
          | translate: { companyName: slimBrandPartner.partnerCompanyName }
      }}
    </h2>
  </div>
  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="assign-budget-section">
        <div class="assign-budget-section-title">
          {{ "brand.partners.editBudget.selectDates" | translate }}
        </div>
        <div class="assign-budget-section-dates">
          <div class="assign-budget-section-dates-item">
            <mat-form-field class="mo-auto-width">
              <mat-label>{{ "shared.startDate" | translate }}</mat-label>
              <input
                matInput
                formControlName="fromDate"
                (dateChange)="validateAndUpdateStartDate($event.value)"
                [min]="minDate"
                [matDatepicker]="fromDate"
                [disabled]="isEditingExistingBudget && invalidDates"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fromDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #fromDate></mat-datepicker>
              <mat-error *ngIf="invalidDates">{{
                "brand.partners.editBudget.invalidStartDate" | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="assign-budget-section-dates-item">
            <mat-form-field class="mo-auto-width">
              <mat-label>{{ "shared.endDate" | translate }}</mat-label>
              <input
                matInput
                formControlName="toDate"
                (dateChange)="validateAndUpdateEndDate($event.value)"
                [min]="selectedBrandPartnerBudget.fromDate | dayjsTransform"
                [max]="maxDate | dayjsTransform"
                [matDatepicker]="toDate"
                [disabled]="isEditingExistingBudget && invalidDates"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="toDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #toDate></mat-datepicker>
              <mat-error *ngIf="invalidDates">{{
                "brand.partners.editBudget.invalidEndDate" | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <span class="shared-mat-error" *ngIf="datesInExistingRange">{{
            "brand.partners.editBudget.datesOverlap" | translate
          }}</span>
        </div>
        <div class="assign-budget-section-currency">
          <div class="assign-budget-section-title">
            {{ "brand.partners.editBudget.selectCurrency" | translate }}
          </div>
          <mat-form-field>
            <mat-select
              formControlName="currency"
              name="currency"
              (selectionChange)="handleCurrencyChange($event.value)"
            >
              <mat-option [value]="campaignCurrency.EUR">{{
                campaignCurrency.EUR
              }}</mat-option>
              <mat-option [value]="campaignCurrency.USD">{{
                campaignCurrency.USD
              }}</mat-option>
              <mat-option [value]="campaignCurrency.GBP">{{
                campaignCurrency.GBP
              }}</mat-option>
              <mat-option [value]="campaignCurrency.CHF">{{
                campaignCurrency.CHF
              }}</mat-option>
              <mat-option [value]="campaignCurrency.SEK">{{
                campaignCurrency.SEK
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="assign-budget-section-amount">
          <div class="assign-budget-section-title">
            {{ "brand.partners.editBudget.selectAmount" | translate }}
          </div>
          <div class="assign-budget-section-amount-slider">
            <mat-slider
              step="1"
              name="budget"
              class="assign-budget-section-amount-slider-bar"
              [max]="maxBudget"
              [min]="0"
              #ngSlider
              ><input
                formControlName="budget"
                matSliderThumb
                (input)="
                  budgetValueChange(
                    {
                      source: ngSliderThumb,
                      parent: ngSlider,
                      value: ngSliderThumb.value
                    }.value
                  )
                "
                #ngSliderThumb="matSliderThumb"
              />
            </mat-slider>
            <div class="assign-budget-section-amount-value">
              <input
                (input)="budgetValueChange($event.target.value)"
                matInput
                min="0"
                max="{{ maxBudget }}"
                name="budgetInput"
                formControlName="budget"
                type="number"
                required
              />
              <span>{{ currencySymbol }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="brandPartner && brandPartner.budgets.length > 0"
        class="budget-list-section"
      >
        <div class="budget-list-section-title">
          {{ "brand.partners.editBudget.allocatedBudgets" | translate }}
        </div>
        <mat-progress-bar
          *ngIf="updatingOrDeleting"
          [color]="'accent'"
          class="progress"
          mode="indeterminate"
        >
        </mat-progress-bar>
        <div *ngIf="brandPartner">
          <div
            class="budget-list-section-item"
            *ngFor="
              let brandPartnerBudget of brandPartner.budgets;
              let i = index
            "
          >
            <div
              *ngIf="
                showDeleteBudgetConfirmMessage &&
                selectedBrandPartnerBudget.id === brandPartnerBudget.id
              "
              class="budget-list-section-item-delete"
            >
              <div>
                {{
                  "brand.partners.editBudget.deleteBudgetConfirm" | translate
                }}
              </div>
              <div>
                <a
                  class="budget-list-section-item-actions-icon-delete-confirm"
                  (click)="cancelDeleteBudget()"
                >
                  <mat-icon>disabled_by_default</mat-icon>
                </a>
                <a
                  class="budget-list-section-item-actions-icon-delete-confirm"
                  (click)="deleteBudget()"
                >
                  <mat-icon>check_box</mat-icon>
                </a>
              </div>
            </div>
            <div
              *ngIf="!showDeleteBudgetConfirmMessage"
              class="budget-list-section-item-info"
            >
              <span class="budget-list-section-item-info-dates">
                {{ "shared.fromDate" | translate }}
                {{ brandPartnerBudget.fromDate | date: getDateFormat() }}
                {{ "shared.toDate" | translate }}
                {{ brandPartnerBudget.toDate | date: getDateFormat() }}
              </span>
              <span class="budget-list-section-item-info-amount">
                {{ brandPartnerBudget.budget }}
                {{ brandPartnerBudget.currency }}
              </span>
            </div>
            <div
              *ngIf="!showDeleteBudgetConfirmMessage"
              class="budget-list-section-item-actions"
            >
              <a
                class="budget-list-section-item-actions-icon budget-list-section-item-actions-icon--small"
                *ngIf="!isEditingBudget(brandPartnerBudget.id)"
                (click)="selectBudgetToEdit(brandPartnerBudget)"
              >
                <mat-icon>edit</mat-icon>
              </a>
              <a
                class="budget-list-section-item-actions-icon budget-list-section-item-actions-icon--red"
                *ngIf="showDeleteBudgetIcon(brandPartnerBudget.id)"
                (click)="deleteBudgetConfirm()"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <a
                class="budget-list-section-item-actions-icon budget-list-section-item-actions-icon--black"
                *ngIf="isEditingBudget(brandPartnerBudget.id)"
                (click)="unselectBudgetToEdit()"
              >
                <mat-icon>disabled_by_default</mat-icon>
              </a>
              <a
                class="budget-list-section-item-actions-icon budget-list-section-item-actions-icon--green"
                *ngIf="isEditingBudget(brandPartnerBudget.id)"
                (click)="updateBrandPartnerBudget()"
              >
                <mat-icon>check_box</mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</ng-container>
<mat-dialog-actions>
  <button class="btn btn--transparent" (click)="close()">
    {{ "shared.cancel" | translate }}
  </button>
  <button
    [disabled]="isSaveDisabled()"
    class="btn btn--primary"
    (click)="createBrandPartnerBudget()"
  >
    <span *ngIf="!creating">{{ "shared.save" | translate }}</span>
    <span *ngIf="creating">{{ "shared.saving" | translate }}</span>
  </button>
</mat-dialog-actions>
