import { SlimBrandPartner } from "../../models/SlimBrandPartner";
import { BrandPartnerListBudget } from "./brand-partner-list-budgets";

export class BrandPartnerList {
  constructor(
    public readonly items: SlimBrandPartner[],
    public readonly totalCount: number,
    public readonly totalBudgets: BrandPartnerListBudget[],
    public readonly totalSpentBudgets: BrandPartnerListBudget[],
    public readonly totalAssignedToCampaignsBudgets: BrandPartnerListBudget[],
    public readonly totalHiddenPartners: number,
  ) {}
}
