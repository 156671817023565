import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { BrandService } from "../../shared/services/api/brand.service";

export function BrandResolverGuard(): CanActivateFn {
  return async (route): Promise<boolean> => {
    const router = inject(Router);
    const brandService = inject(BrandService);

    const brandId = parseInt(route.paramMap.get("brandId")!, 10);
    const lastSelectedBrandId = brandService.lastSelectedBrandId;

    const isBrandIdCorrect = !isNaN(brandId);
    const isNewBrandSelected = brandId !== lastSelectedBrandId;
    const isCurrentBrandNotLoaded = !brandService.currentBrand$.value;

    if (!isBrandIdCorrect) {
      await router.navigate(["/not-found"]);
      return false;
    }

    if (isNewBrandSelected || isCurrentBrandNotLoaded) {
      try {
        await brandService.setCurrentBrandId(brandId);
      } catch (err) {
        await router.navigate(["/welcome"]);
        return false;
      }
    }
    return true;
  };
}
