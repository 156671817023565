export enum GetBrandPartnersFiltersItemKey {
  CompanyName,
}

export enum GetBrandPartnersOrderBy {
  CompanyName = "company_name",
  BudgetAssignedToCampaigns = "budget_assigned_to_campaigns",
}

export enum GetBrandPartnersOrderByDirection {
  Desc = "Desc",
  Asc = "Asc",
}

export class GetBrandPartnersFiltersItem {
  constructor(
    public readonly key: GetBrandPartnersFiltersItemKey,
    public readonly value: string,
  ) {}
}
