import dayjs from "dayjs";

export class CreateBrandPartnerBudgetItemData {
  public brandPartnerId: number;
  public brandPartnerCompanyName: string;
  public fromDate: string;
  public toDate: string;
  public budget: number;
  public currency: string;
  constructor(
    brandPartnerId: number,
    brandPartnerCompanyName: string,
    fromDate: Date,
    toDate: Date,
    budget: number,
    currency: string,
  ) {
    this.brandPartnerId = brandPartnerId;
    this.brandPartnerCompanyName = brandPartnerCompanyName;
    this.fromDate = dayjs(fromDate).format();
    this.toDate = dayjs(toDate).format();
    this.budget = budget;
    this.currency = currency;
  }
}
