import { Component, OnInit } from "@angular/core";
import { Brand } from "../../features/brand/domain/models/brand";
import { BrandService } from "../../shared/services/api/brand.service";

@Component({
  selector: "app-brand-settings",
  templateUrl: "./brand-settings.component.html",
  styleUrls: ["./brand-settings.component.scss"],
})
export class BrandSettingsComponent implements OnInit {
  public brand?: Brand;

  constructor(private readonly brandService: BrandService) {}

  public async ngOnInit(): Promise<void> {
    this.brandService.currentBrand$.subscribe((brand) => {
      this.brand = brand;
    });
  }
}
