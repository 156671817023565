<section class="brand-logo">
  <div>
    <div class="brand-logo-title">
      {{ "brand.settings.uploadLogo.title" | translate }}
    </div>
    <div class="brand-logo-intro">
      {{ "brand.settings.uploadLogo.intro" | translate }}
    </div>

    <div *ngIf="brand && !brand.logo" class="brand-logo__placeholder">
      {{ "brand.settings.uploadLogo.placeholderText" | translate }}
    </div>
    <div *ngIf="brand && brand.logo" class="brand-logo__container">
      <img
        class="brand-logo__delete-button"
        (click)="deleteLogoConfirm()"
        src="/assets/images/as_delete_logo.svg"
      />
      <img
        class="brand-logo__image"
        width="152"
        height="152"
        [src]="brand.logo"
      />
    </div>
    <app-file-uploader
      [maxFileSize]="MaxFileSize"
      [uploaderTitle]="'brand.settings.uploadLogo.uploadTitle' | translate"
      [uploaderSubtitle]="
        'brand.settings.uploadLogo.uploadSubtitle'
          | translate: { maxFileSize: MaxFileSize }
      "
      (apiResponse)="handleResponse($event)"
      [uploadUrl]="brandUploadLogoUrl"
      (uploadError)="fileUploaderErrorHandler($event)"
      [responseMapper]="AnyToImageEntityMapper"
    />
  </div>
</section>
