import { Injectable } from "@angular/core";
import dayjs from "dayjs";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BrandNotSelectedError } from "../../../shared/errors/brand-not-selected-error";
import { BrandPartner } from "../../../shared/models/brandPartner";
import { SlimBrandPartner } from "../../../shared/models/SlimBrandPartner";
import { BrandService } from "../../../shared/services/api/brand.service";
import { HttpClient } from "../../../shared/services/http-client";
import {
  GetBrandPartnersFiltersItem,
  GetBrandPartnersFiltersItemKey,
  GetBrandPartnersOrderBy,
  GetBrandPartnersOrderByDirection,
} from "../../../shared/services/parameters/get-brand-partners-filters-item";
import { BrandPartnerList } from "../../../shared/services/responses/brand-partner-list";
import { BrandPartnerListBudget } from "../../../shared/services/responses/brand-partner-list-budgets";

@Injectable()
export class BrandPartnerService {
  constructor(
    private http: HttpClient,
    private brandService: BrandService,
  ) {}

  private getCurrentBrandId(): number {
    return this.brandService.currentBrandId;
  }

  public getBrandPartners(
    offset = 0,
    limit = 50,
    includeHidden = false,
    filterTags: GetBrandPartnersFiltersItem[] = [],
    filterStartDate: Date,
    filterEndDate: Date | null,
    filterOrderBy: GetBrandPartnersOrderBy | null = null,
    filterOrderByDirection: GetBrandPartnersOrderByDirection | null = null,
  ): Observable<BrandPartnerList> {
    const brandId = this.getCurrentBrandId();
    if (!brandId) {
      throw new BrandNotSelectedError();
    }

    let url = `brand/${brandId}/partners?offset=${offset}&limit=${limit}`;

    if (includeHidden) {
      url = url + `&include_hidden=1`;
    }

    const companyNameTags = filterTags.filter(
      (filterTag: GetBrandPartnersFiltersItem) =>
        filterTag.key === GetBrandPartnersFiltersItemKey.CompanyName,
    );

    if (companyNameTags.length > 0) {
      let brandPartnerCompanyNameFilter = "";
      companyNameTags.map((filterTag: GetBrandPartnersFiltersItem) => {
        brandPartnerCompanyNameFilter =
          brandPartnerCompanyNameFilter + filterTag.value + ",";
      });

      url =
        url +
        `&brandPartnerCompanyNameFilter=` +
        brandPartnerCompanyNameFilter.slice(0, -1);
    }

    if (filterStartDate) {
      url = url + `&fromDate=` + dayjs(filterStartDate).unix();
    }

    if (filterEndDate) {
      url = url + `&toDate=` + dayjs(filterEndDate).unix();
    }

    if (filterOrderBy) {
      url = url + `&filterOrderBy=` + filterOrderBy;
    }

    if (filterOrderByDirection) {
      url = url + `&filterOrderByDirection=` + filterOrderByDirection;
    }

    return this.http.get(url).pipe(
      map(
        ({ body }) =>
          new BrandPartnerList(
            body.items.map((item: any) => new SlimBrandPartner(item)),
            body._meta?.totalCount ?? 0,
            body._meta.totalBudgets.map(
              (d: any) =>
                new BrandPartnerListBudget(
                  d.amount,
                  d.currency,
                  d.currencySymbol,
                ),
            ),
            body._meta.totalSpentBudgets.map(
              (d: any) =>
                new BrandPartnerListBudget(
                  d.amount,
                  d.currency,
                  d.currencySymbol,
                ),
            ),
            body._meta.totalAssignedToCampaignsBudgets.map(
              (d: any) =>
                new BrandPartnerListBudget(
                  d.amount,
                  d.currency,
                  d.currencySymbol,
                ),
            ),
            body._meta.totalHiddenPartners,
          ),
      ),
    );
  }

  public getBrandPartner(brandPartnerId: number): Observable<BrandPartner> {
    const url = `brand/${this.getCurrentBrandId()}/partner/${brandPartnerId}`;
    return this.http.get(url).pipe(map(({ body }) => new BrandPartner(body)));
  }

  public updateBrandPartners(
    brandPartners: SlimBrandPartner[],
  ): Observable<BrandPartner> {
    const url = `brand/${this.getCurrentBrandId()}/partners`;

    return this.http
      .put(url, { brandPartners: brandPartners })
      .pipe(map(({ body }) => new BrandPartner(body)));
  }
}
